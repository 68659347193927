import clsx from 'clsx';
import React, { FC, HTMLAttributes } from 'react';
import { IWithClassName } from '~/models/dev';
import Text from '~/shared/text/text.component';
import styles from './heading.module.scss';

type tagType = Pick<JSX.IntrinsicElements, 'h1' | 'h2' | 'h3' | 'h4'>;

interface IProps extends IWithClassName, HTMLAttributes<HTMLHeadingElement> {
    tagName: keyof tagType;
    displayStyle?: keyof tagType;
    nowrap?: boolean;
    textAlign?: 'left' | 'center' | 'right';
    subHeading?: string | JSX.Element;
    externalSubHeading?: boolean;
}

const Heading = React.forwardRef<HTMLHeadingElement, IProps>(
    ({ className, tagName, children, displayStyle, nowrap, textAlign, subHeading, externalSubHeading, ...rest }, ref) => {
        const Tag = tagName as keyof tagType;
        const tagStyle = displayStyle || tagName;

        return (
            <>
                <Tag
                    ref={ref}
                    className={clsx(className, styles[tagStyle], styles[textAlign as string], {
                        [styles.nowrap]: nowrap,
                        [styles.withSubHeading]: !!subHeading || externalSubHeading,
                    })}
                    {...rest}
                >
                    {children}
                </Tag>
                {subHeading && <SubHeading>{subHeading}</SubHeading>}
            </>
        );
    },
);

interface ISubHeadingProps extends IWithClassName {
    nowrap?: boolean;
    textAlign?: 'left' | 'center' | 'right';
}

export const SubHeading: FC<ISubHeadingProps> = ({ className, children, textAlign, nowrap }) => (
    <Text
        textStyle="bodySmall"
        className={clsx(className, styles[textAlign as string], {
            [styles.nowrap]: nowrap,
        })}
    >
        {children}
    </Text>
);

export default Heading;
