import React, { Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { CdnImage } from '~/shared/image/cdn-image';
import { useStaticContent } from '~/libs/queries/bff';
import { Bff } from '~/models/bff.d';
import { CmsData } from '~/models/cms-data.d';
import { authRedirectEndpoints } from '~/services/auth-endpoints';
import Button from '~/shared/buttons/button/button.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Svg } from '~/shared/svg';
import styles from './login-hero.module.scss';
import Heading from '~/shared/heading/heading.component';
import { signIn } from 'next-auth/react';

export interface ILoginHeroProps {
    catalogLink: Bff.ILink;
    image: CmsData.ICmsImage;
    caption: string;
}

const LoginHero: FunctionComponent<ILoginHeroProps> = ({ image, caption }) => {
    const translate = useTranslations();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const { loggedInPageUrl, internalLoggedInPageUrl } = useStaticContent();
    const [isLoadingLogin, setIsLoadingLogin] = useState(false);
    const [isLoadingInternalLogin, setIsLoadingInternalLogin] = useState(false);

    const timer = useRef(null);

    // Start a fake loader on click
    const startLoading = useCallback((stateAction: Dispatch<SetStateAction<boolean>>) => {
        stateAction(true);
        // ignore typescript error
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        timer.current = setTimeout(() => {
            stateAction(false);
        }, 5000);
    }, []);

    useEffect(() => {
        if (!timer.current) {
            return;
        }

        return () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            clearTimeout(timer.current);
        };
    }, []);

    const handleExternalLogin = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            startLoading(setIsLoadingLogin);

            signIn('identity-server4', {
                callbackUrl: loggedInPageUrl.replace(/\/$/, ''),
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setIsLoadingLogin, loggedInPageUrl],
    );

    const handleInternalLogin = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            startLoading(setIsLoadingInternalLogin);

            signIn('identity-server4-internal', {
                callbackUrl: internalLoggedInPageUrl.replace(/\/$/, ''),
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setIsLoadingInternalLogin, internalLoggedInPageUrl],
    );

    // Clear timer on unmount
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    useEffect(() => () => clearTimeout(timer.current), []);

    return (
        <section className={clsx(styles.loginWidget, 'container-fluid')}>
            <div className={styles.mainContent}>
                <div className={styles.imageWrapper}>
                    {image && (
                        <CdnImage
                            src={image?.src}
                            alt={image?.name}
                            width={image?.width}
                            height={image?.height}
                            layout="responsive"
                            objectFit="contain"
                        />
                    )}
                </div>

                <div className={styles.captionGroup}>
                    {caption && (
                        <Heading tagName="h1" displayStyle="h4" className={styles.caption}>
                            {caption}
                        </Heading>
                    )}

                    <Button
                        href={authRedirectEndpoints.login}
                        fetching={isLoadingLogin}
                        onClick={handleExternalLogin}
                        className={styles.loginButton}
                        icon={<Svg name="lock" className={styles.icon} />}
                        shouldRenderStaticLink
                    >
                        {translate('loginHero.login', 'Log ind')}
                    </Button>

                    <br />
                    {/* <Button
              href={catalogLink.url ?? '/'}
              buttonStyle="secondaryLight"
              className={styles.button}
            >
              {translate('loginHero.accessCatalog', 'Få adgang til kataloget')}
            </Button> */}
                    <Button
                        className={styles.ftzLoginBtn}
                        buttonStyle="secondary"
                        href={authRedirectEndpoints.ftzLogin}
                        fetching={isLoadingInternalLogin}
                        onClick={handleInternalLogin}
                        icon={<Svg name="lock" className={styles.icon} />}
                        shouldRenderStaticLink
                    >
                        {translate('loginHero.ftzLogin', 'FTZ medarbejder log ind')}
                    </Button>
                </div>
            </div>
        </section>
    );
};

export default LoginHero;
